@tailwind base;
@tailwind components;
@tailwind utilities;

:root,
html,
body {
    height: 100%;
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}
